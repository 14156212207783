<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'SettingsV2',
  page: {
    title: 'System Settings',
    meta: [
      {
        name: 'description',
        content: 'System Settings',
      },
    ],
  },
}
</script>
